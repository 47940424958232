import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../../components/dashboard/Dashboard-View.vue')
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import('../../components/devices/Devices-Overview-View.vue')
  },
  {
    path: '/thresholds',
    name: 'Thresholds',
    component: () => import('../../components/Threshold/Threshold-View.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../../components/settings/Settings.vue')
  },
  {
    path: '/manage',
    name: 'Manage',
    component: () => import('../../components/manage/Manage.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
