import { Module } from 'vuex'
import { RootState } from '@/application/store/RootStore'
import Hospital from '@/application/models/Hospital'
import { BACKEND_API } from '@/application/store/BackendAPI'
import Device from '@/application/models/Device'

export const Devices: Module<any, RootState> = {
  namespaced: true,
  state: {
    devices: Array<Device>()
  },

  getters: {
    devices: ({ devices }) => devices,
    device: ({ devices }, id: string) => devices.find((device: Device) => device.id === id)!
  },

  mutations: {
    devices: (state, newDevices: Array<Device>) => {
      state.devices = newDevices
    }
  },

  actions: {
    FETCH_DEVICES_DATA: ({ commit }) => {
      return new Promise<Array<Device>>(resolve => {
        BACKEND_API.REST.DEVICES.then((devices: Array<Device>) => {
          commit('devices', devices)
          console.log(devices)
          resolve(devices)
        })
      })
    }
  }
}
