import { RootState } from '@/application/store/RootStore'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import Patient from '@/application/models/Patient'
import { BACKEND_API } from '@/application/store/BackendAPI'
import Hospital from '@/application/models/Hospital'
import PatientVitalsSources from '@/application/models/PatientVitalsSources'

export type PatientAction = {
  FETCH_PATIENTS_DATA: 'FETCH_PATIENTS_DATA';
  CREATE_PATIENT: 'CREATE_PATIENT';
  ADMIT_PATIENT: 'ADMIT_PATIENT';
}

export const PatientsModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    patients: Array<PatientVitalsSources>()
  },

  getters: {
    patients: ({ patients }) => patients,
    patient: ({ patients }, id: string) => patients.find((p: Patient) => p.id === id)!
  },

  mutations: {
    patients: (state, newPatients: Array<Patient>) => {
      console.log('mutation of patients ', newPatients)
      state.patients = newPatients
    },
    addPatients: (state, newPatient: Patient) => {
      console.log('mutation of patients ', newPatient)
      state.patients.push(newPatient)
    }
  },

  actions: {
    FETCH_PATIENTS_DATA: ({ commit }, patientProviderId: string) => {
      return BACKEND_API.REST.GET_PATIENTS(patientProviderId).then((patients: Array<Patient>) => {
        commit('patients', patients)
        return patients
      })
    },
    FETCH_ADMIT_PATIENTS_DATA: ({ commit }, patientProviderId: string) => {
      return BACKEND_API.REST.GET_ADMIT_PATIENTS(patientProviderId).then((patients: Array<PatientVitalsSources>) => {
        commit('patients', patients)
        return patients
      })
    },
    CREATE_PATIENT: ({ commit, dispatch, rootGetters }, patient: Patient) => {
      return BACKEND_API.REST.POST_PATIENTS(patient).then((respPatient: Patient) => {
        const selectedHospital: Hospital = rootGetters['hospitals/selectHospital']
        dispatch('FETCH_PATIENTS_DATA', selectedHospital.patientDataProvider?.id).then((patients: Array<Patient>) => {
          dispatch('ADMINT_PATIENT', { hospital: selectedHospital, patient: respPatient }).then(() => {
            commit('addPatients', respPatient)
          })
        })
      }).catch(error => console.error(error))
    },
    ADMINT_PATIENT: ({ commit }, { patient, hospital }) => {
      return BACKEND_API.REST.ADMIT_PATIENT(hospital.id, patient.id!)
    },
    DISCHARGE_PATIENT: ({ commit }, { patient, hospital }) => {
      return BACKEND_API.REST.DISCHARGE_PATIENT(hospital.id, patient.id!)
    }
  }
}
